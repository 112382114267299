body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
}

.btn-primary {
  background-color: #f0c040;
  color: #000000;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}

.rating span {
  color: #ffc000;
}

.product img {
  width: 100%;
  max-width: 400px;
}

.product-info {
  padding: 1rem;
}

.img-large {
  max-width: 100%;
}
